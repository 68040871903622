@import "../../../../style/abstracts/mixins";

.signUpInterests {
  &__form {
    width: 80%;
    min-width: 40rem;
    margin: auto;
    margin-bottom: 5rem;
  }
  &__title {
    text-align: center;
  }
  &__grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    row-gap: 2rem;
    column-gap: 2rem;
    width: 100%;
    margin-bottom: 2rem;
    @include respond(tab-port) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__input {
    // background-color: blue;
    height: 10rem;
    font-size: 1.7rem;
    // width: 10rem;
  }
  &__submit {
    text-align: center;
  }
  &__counter {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}
