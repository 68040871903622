@import "../../../style/abstracts/mixins";

.articleScreen {
  background-color: var(--color-background-default);
  &__heading {
    margin-bottom: 2rem;
  }
  &__paragraph {
    margin-bottom: 3rem !important;
  }
  &__listItem {
    margin-left: 3rem;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  &__quote {
    text-align: center;
    font-size: 3rem;
    margin-left: 3rem;
    margin-right: 3rem;
    font-weight: normal;
  }
}

.articleScreenMainImage {
  margin: auto;
  width: 100%;
  padding-bottom: 50%;
  background-size: cover;
  background-position: center;
}

.articleScreenMainHeading {
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-primary);
  margin-top: -3rem;
  // height: 20rem;
  @include respond(tab-land) {
    // padding-bottom: 40%;
  }
  @include respond(phone-port) {
    // padding-bottom: 60%;
  }
  &__image {
    flex-basis: 50%;
  }
  &__content {
  }
  &__name {
    color: var(--color-white);
    font-size: 10rem;
    font-weight: bold;
    margin-bottom: 2rem;
    @include respond(tab-land) {
      font-size: 7rem;
    }
    @include respond(phone-port) {
      font-size: 5rem;
    }
    @include respond(phone-land) {
      font-size: 4rem;
    }
  }
}

.articleSuggestions {
  &__articles {
    @include respond(tab-port) {
      grid-template-columns: repeat(1, 1fr);
    }
    // width: fit-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
  }
}

.articleComments {
  &__new {
    margin-bottom: 2rem;
  }
}

.paragraphImage {
  padding-bottom: 50%;
  background-size: cover;
  background-position: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
