.mentorCareer {
  &__mainHeading {
    color: var(--color-black);
  }
  &__seeMoreEditableBox {
    font-size: 2rem;
    font-weight: bold;
    width: 20rem;
    text-align: right;
    min-height: fit-content;
    margin-left: auto;
    display: block;
  }
  &__salaryEditableBox {
    width: min-content;
    font-weight: bold;
    display: inline-block;
  }
  &__salaryBoxes {
    font-size: 2rem;
    // display: inline;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__addSection {
    height: 30rem;
    font-size: 10rem;
  }
}

.mentorCareer {
  &Salaries {
    &__dash {
      display: flex;
      align-items: center;
      font-size: 3rem;
      margin: 0 1rem;
    }
    &__salaryGroup {
      align-items: center;
      font-size: 1.5rem;
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
    }
  }
}
