@import "../../../style/abstracts/mixins";

.careerScreen {
  background-color: var(--color-background-default);
  overflow: hidden;
  &__heading {
    margin-bottom: 2rem;
  }
  &__section {
    background-color: var(--color-white);
    padding: 2rem;
    margin-bottom: 2rem;
    &__title {
      display: flex;
      align-items: center;
      font-size: 2.5rem;
      margin-bottom: 2rem;
      font-weight: bold;
      font-family: var(--font-secondary);
      &__image {
        display: inline-block;
        height: 5rem;
        width: 5rem;
        margin-right: 1rem;
      }
    }
  }
  &__sectionTitle {
    margin-bottom: 2rem;
  }
  &__sectionContent {
    margin-bottom: 2rem;
  }
  &__sectionSeeMore {
    width: 100%;
    text-align: right;
    font-size: 2rem;
    display: inline-block;
    color: var(--color-primary-dark);
    font-weight: bold;
    margin-top: 2rem;
    margin-left: auto;
  }
  &__mainHeading {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-left: calc(var(--padding-screen-large-side) * (-1.7));
    margin-right: calc(var(--padding-screen-large-side) * (-1.7));

    @include respond(tab-port) {
      margin-left: calc(-7rem * 1.7);
      margin-right: calc(-7rem * 1.7);
    }
    @include respond(phone-land) {
      margin-left: calc(var(--padding-screen-small-side) * (-1.7));
      margin-right: calc(var(--padding-screen-small-side) * (-1.7));
    }
    margin-top: calc(var(--padding-screen-large-top) * (-1));
    padding-top: 10rem;
    padding-bottom: 5rem;
    background-color: var(--color-primary);
    text-align: center;
    margin-bottom: 2rem;
    border-radius: 50%;
  }
  &__joinCareer {
    margin-top: 2rem;
  }
}

.careerQuotes {
  margin-left: -2rem;
  margin-right: -2rem;
  margin-bottom: 5rem;
  padding: 4rem;
  border: solid 2px var(--color-tertiary);
  &__quotes {
    // width: fit-content;
    @include respond(tab-port) {
      grid-template-columns: repeat(1, 1fr);
    }
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
  }
  .quote {
    &__text {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    &__author {
      width: 50%;
    }
  }
}

.careerVideos {
  // padding: 5rem;
  &__videos {
    @include respond(tab-port) {
      grid-template-columns: repeat(2, 1fr);
    }
    // width: fit-content;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
  }
}

.careerMentors {
  &__mentors-section {
    padding: 0 !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  &__mentors {
    @include respond(tab-land) {
      grid-template-columns: repeat(3, 1fr);
    }
    // width: fit-content;
    display: grid;
    row-gap: 2rem;
    column-gap: 2rem;

    @media screen and (min-width: 800px) {
      grid-template-rows: repeat(4, 1fr);
      grid-template-columns: 0.8fr;
      // grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 799px) {
      grid-template-rows: repeat(4, 1fr);
      grid-template-columns: 1fr;  
    }
    // justify-items: center;;
  }
}

.careerArticles {
  &__articles {
    @include respond(tab-port) {
      grid-template-columns: repeat(1, 1fr);
    }
    // width: fit-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
  }
}

.careerPotentialIndustries {
}

.careerScreenSalaries {
  &__salaryGroup {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
  }
  &__disclaimer {
    text-align: right;
  }
  &__annualSign {
    font-size: 1.5rem;
    color: var(--color-primary);
  }
}

.careerScreenDescription {
  &__buttons {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.careerScreenAllMentors {
  padding: 2rem;
  display: grid;
  display: grid;
  row-gap: 2rem;
  column-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: min-content;
  @include respond(tab-port) {
    grid-template-columns: repeat(1, 1fr);
  }
}
