@import "../../../style/abstracts/mixins";

.buttonDefault {
  word-break: break-word;
  cursor: pointer;
  transition: all 0.3s;
  border: none;
  padding: 1rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
  font-family: var(--font-secondary);
  outline: none;
  background-color: var(--color-primary);
  color: var(--color-black);
  &:hover {
    background-color: var(--color-primary-dark);
  }
}
