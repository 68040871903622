// @import '../../style/style.scss';

.signInScreen {
  &__sub {
    text-align: center;
    margin-bottom: 2rem;
  }
  &__heading {
    text-align: center;
    margin-bottom: 1rem;
  }
  &__form {
  }
  &__gotoSignUp {
    text-decoration: underline;
    color: var(--color-primary);
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  &__forgot {
    cursor: pointer;
    text-align: center;
    font-size: 2rem;
    color: var(--color-primary);
    text-decoration: underline;
  }
}
