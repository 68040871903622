@import "../../../style/abstracts/mixins";

.mentorScreenSubscribe {
  &__buttons {
    text-align: center;
  }
}

.mentorScreenMentorCareers {
  // width: fit-content;
  @include respond(phone) {
    grid-template-columns: repeat(1, 1fr);
  }
  margin-left: -2rem;
  margin-right: -2rem;
  margin-bottom: 5rem;
  padding: 2rem;
  border: solid 2px var(--color-tertiary);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 2rem;
  column-gap: 2rem;
}

.mentorScreenHeader {
  // display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;
  // height: fit-content;

  &__imageMain {
    margin-top: -3rem;
    overflow: hidden;
    background-size: cover;
    margin-bottom: 5rem;
    padding-bottom: 45%;
    background-position: center 20%;
    @include respond(tab-land) {
      padding-bottom: 70%;
    }
    @include respond(phone-port) {
      padding-bottom: 90%;
      background-position: center;
    }
  }

  &__mentorDetails {
    justify-items: center;
    display: grid;
    row-gap: 1rem;
    @include respond(tab-land) {
      row-gap: 1rem;
    }
  }

  &__name {
    @include respond(tab-land) {
      font-size: 7rem;
    }
    @include respond(tab-port) {
      font-size: 6rem;
    }
    @include respond(phone-land) {
      font-size: 5rem;
    }
    @include respond(phone-port) {
      font-size: 3rem;
    }
    font-weight: 900;
    font-size: 6rem;
    width: max-content;
  }
  &__job {
    @include respond(tab-land) {
      font-size: 4rem;
    }
    @include respond(tab-port) {
      font-size: 3rem;
    }
    @include respond(phone-land) {
      font-size: 2rem;
    }
    @include respond(phone-port) {
      font-size: 2rem;
    }
    font-size: 5rem;
  }

  &__links {
    @include respond(phone-land) {
    }
    display: flex;
    width: fit-content;
    justify-content: space-between;
  }

  &__link {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
  }
}

.mentorScreenMainDescription {
  &__text {
    @include respond(tab-land) {
      font-size: 3rem;
      // line-height: rem;
    }
    @include respond(tab-port) {
      font-size: 2rem;
      line-height: 3rem;
    }
    @include respond(phone-land) {
      font-size: 2.5rem;
      line-height: 3.5rem;
    }
    @include respond(phone-port) {
      font-size: 2rem;
      line-height: 3rem;
    }
    font-size: 3rem;
  }
}
