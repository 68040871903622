.formEditable {
  &__children {
    cursor: pointer;
  }
  &__buttons {
    margin-top: 2rem;
    display: grid;
    row-gap: 1rem;
    column-gap: 1rem;
  }
  &__addButton {
    height: 30rem;
    font-size: 10rem;
  }
}
