.formSingleInput {
  display: grid;
  align-items: stretch;
  width: 100%;
  grid-template-columns: 3fr 1fr;
  column-gap: 1rem;
  label {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0 !important;
  }
}
