.announcement-edit {

    @media only screen and (min-width: 1441px) {
        margin-left: 27.5rem;

        &__container {
            width: 1000px; 
        }
    }

    @media only screen and (min-width: 960px) and (max-width: 1440px){
        margin-left: 27.5rem;

        &__container {
            margin-left: 27.5rem;
            width: 75%; 

        }
    }

    @media only screen and (min-width: 650px) and (max-width: 960px) {
        width: 65%; 
    }

    &__container {
        margin: auto;
    }

    &__container--underline {
        width: 90%;
        margin: 2rem auto;
        border-bottom: 1px solid #DADADA;
    }

    &__h1 {
        color: #272727;
        font-size: 28px;
        margin: 2rem auto;
        display: block;

    }

    &--background {
        background-color: var(--color-background-default);
    }
}
