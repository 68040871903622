.blog-card {
    padding: 25px;
    color: #272727;
    font-size: 16px;
    border-radius: 20px 20px 0px 0px;
    background: white;
    box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.15);
    text-overflow: ellipsis;

    &__title {
        padding: 10px 0;
    }

    &__author {
        &-section {
            margin-top: 16px;
            display: flex;

            & > * {
                margin-right: 10px;
            }
        }

        &-pfp {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            object-fit: cover;
        }

        &-name {
            font-weight: 500;
        }

        &-career {
            font-size: 14px;
        }
    }

    &__hr {
        border: 1px solid #C4C4C4;
    }

    &__img {
        border-radius: 0px 0px 20px 20px;
        width: 100%;
        object-fit: cover;
        box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.15);
        height: 200px;
    }
}
