.announcement-card {
    padding: 25px;
    color: #272727;
    font-size: 16px;
    border-radius: 20px 20px 0px 0px;
    background: white;
    box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.15);
    text-overflow: ellipsis;

    &__title {
        padding: 10px 0;
    }

    &__time {
        color: #757575;
        text-overflow: ellipsis;
    }

    &__img {
        border-radius: 0px 0px 20px 20px;
        width: 100%;
        object-fit: cover;
        box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.15);
        height: 200px;
    }
}
