// @import '../../style/style.scss';

.career {
  width: 100%;
  &__title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  &__description {
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }
  &__button {
    font-size: 1.7rem;
  }
}
