.track {
  height: 1rem;
  width: 100%;
  // display: flex;
  padding: 0.2rem;
  background-color: black;
  // align-items: center;
  // justify-content: flex-start;
  border-radius: 1000rem;
  &__progress {
    transition: width 0.5s;
    border-radius: 1000rem;
    height: 100%;
    background-color: green;
  }
}
