@import "../../../../style/abstracts/mixins";

.settingsInterests {
  &__grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    row-gap: 2rem;
    column-gap: 2rem;
    width: 100%;
    margin-bottom: 2rem;
    @include respond(tab-port) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__counter {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}
