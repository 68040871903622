body {
  font-family: var(--font-primary), sans-serif;
  font-weight: 400;
  /*font-size: 16px;*/
  color: var(--color-dark-grey);
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.28571429em;
  margin: 0 0 1rem 0;
  font-weight: 700;
  padding: 0;
}

.heading-primary {
  &::after {
  }
  &--main {
    @include respond(phone) {
      letter-spacing: 1rem;
      font-family: 5rem;
    }
    /*
        animation-delay: 3s;
        animation-iteration-count: 3;
        */
  }
}

.heading-secondary {
  font-size: 4rem;
  font-family: var(--font-secondary);
  letter-spacing: 0.2rem;
  font-weight: normal;

  @include respond(tab-port) {
    font-size: 3.5rem;
  }
}

.heading-tertiary {
  font-weight: bold;
  line-height: 150%;
  @include respond(tab-port) {
    font-size: 3rem;
  }
  @include respond(phone-land) {
    font-size: 3rem;
    // line-height: 3.5rem;
  }
  @include respond(phone-port) {
    font-size: 2.5rem;
    // line-height: 2.8rem;
  }
  font-size: var(--default-font-size);
  font-weight: bold;
}

.paragraph {
  line-height: 3rem;
  font-size: var(--default-font-size);
  font-family: var(--font-secondary);
  // word-break: break-all;
  line-height: 4rem;

  @include respond(phone-land) {
    font-size: 2.5rem;
    line-height: 3.7rem;
  }
  @include respond(phone-port) {
    font-size: 1.8rem;
    line-height: 3rem;
  }

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
}

.font-bold {
  font-weight: bold !important;
}

.font-light {
  font-weight: lighter !important;
}

.text-center{
  text-align: center;
}