.taskTracker {
  &__task {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    margin-bottom: 2rem;
  }
  &__description {
    font-size: 1.5rem;
  }
  &__type {
    font-size: 2rem;
    // font-weight: normal;
    text-transform: uppercase;
  }
  &__track {
    margin-bottom: 1rem;
  }
  &__info {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    align-items: center;
    column-gap: 1rem;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  &__points {
    display: flex;
    align-items: center;
    font-size: 2.5rem;
    font-weight: bold;
  }
  &__progress {
    font-size: 2rem;
    letter-spacing: 0.1rem;
    text-align: right;
  }
}
