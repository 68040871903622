.formInput {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__input {
    font-size: 1.5rem;
    font-family: inherit;
    color: inherit;
    padding: 1.5rem 2rem;
    background-color: rbga(white, 0.5);
    border: 1px solid var(--color-primary);
    width: 100%;
    display: block;
    transition: all 0.3s;
    outline: none;

    &:valid {
      box-shadow: 0 1rem 2rem rgba(black, 0.1);
      border-bottom: 3px solid var(--color-primary);
    }

    &:not(:placeholder-shown):invalid {
      border-bottom: 3px solid var(--color-error);
      //   border-bottom: 3px solid $color-secondary-dark;
    }

    &::-webkit-input-placeholder {
      color: var(--color-grey-dark-2);
    }
  }

  &__label {
    font-size: 1.2rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
  }
}
