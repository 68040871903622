@import "../../../style/abstracts/mixins";

.videosListScreen {
  &__heading {
    margin-bottom: 2rem;
  }
}

.videosListScreenVideos {
  display: grid;
  row-gap: 2rem;
  column-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  @include respond(tab-land) {
    grid-template-columns: repeat(1, 1fr);
  }
  // @include respond(phone-port){
  //   grid-template-columns: repeat(1, 1fr);
  // }
}
