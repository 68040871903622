.webMentorThumbnail {
    display: flex;
    padding: 2rem;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-radius: 1rem;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
        0px 1px 8px rgba(0, 0, 0, 0.1);

    &__read-more {
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0.6)
            ),
            #4dc591;
        padding:1.5rem 2rem;
        color: white;
        border-radius: 50px;
        font-size: 16px;
    }

    &__thumbnail {
        box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.15);
        transition: all 0.5s;
        width: 10rem;
        background-color: var(--color-black);
        padding-bottom: 10rem;
        border-radius: 50%;
        margin: 0;
        background-size: 120%;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 5%;
    }

    &__text {
        flex: 1;
        max-width: 15rem;
        // margin-right: auto;

        &-wide {
            max-width: 10rem;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }
    }
}
