html {
  // This defines what 1rem is
  font-size: 62.5% !important; //1 rem = 10px; 10px/16px = 62.5%

  @include respond(tab-land) {
    // width < 1200?
    font-size: 56.25% !important; //1 rem = 9px, 9/16 = 50%
  }

  @include respond(tab-port) {
    // width < 900?
    font-size: 50% !important; //1 rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 75% !important; //1rem = 12, 12/16
  }
  // width: fit-content;
}

svg {
  vertical-align: top;
}

body {
  box-sizing: border-box;
  @include respond(tab-port) {
    padding: 0;
  }
  *,
  *::after,
  *::before,
  p,
  a {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    line-height: normal;
    text-decoration: none;
    color: inherit;
  }
}

.App {
  width: 100vw;
}

::selection {
  background-color: var(--color-primary);
}
