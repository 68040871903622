@import "../../../../style/abstracts/mixins";

.navDropdown {
  position: fixed;
  &__bg {
    animation: dropDownBg 0.2s ease-in;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.25;
  }
  &__content {
    animation: dropDownSlide 0.2s ease-in;
    padding-bottom: 2rem;
    color: black;
    position: fixed;
    top: 7rem;
    right: 0;
    // height: 40rem;
    width: 30rem;
    background-color: white;
    box-shadow: 8px 8px 20px 0px rgba(0, 0, 0, 0.25);
  }
  &__head {
    padding: 2rem;
    background-color: var(--color-grey-light-3);
  }
  &__section {
    margin: 0.5rem 2rem;
    border-bottom: 1px solid;
    padding-bottom: 1rem;
    &:last-child {
      border-bottom: none;
    }
    @include respond(tab-port) {
      margin: 1rem 2rem;
    }
  }
  &__name {
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }
  &__signout {
    cursor: pointer;
    width: fit-content;
    font-size: 1.5rem;
    border-bottom: 1px solid;
  }
  &__sectionHeader {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: var(--color-primary);
    @include respond(tab-port) {
      margin-bottom: 1rem;
      font-size: 2rem;
    }
  }
  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 1rem 0.5rem;
    @include respond(tab-port) {
      padding: 1rem;
      margin-bottom: 0.5rem;
      font-size: 1.7rem;
    }
    &:hover {
      background-color: var(--color-grey-light-2);
    }
  }
  &__itemText {
    width: fit-content;
    font-size: 1.3rem;
    font-family: var(--font-secondary);
    letter-spacing: 2px;
    // padding-bottom: 1px;
    border-bottom: 1px solid;
    @include respond(tab-port) {
      font-size: 1.7rem;
    }
  }
  &__icon {
    // background-color: grey;
    height: 2rem;
    width: 2rem;
    margin-right: 2rem;
  }
}

@keyframes dropDownSlide {
  0% {
    // visibility: hidden;
    right: -100%;
  }
  //   100% {
  //     left: 0;
  //   }
}

@keyframes dropDownBg {
  0% {
    // visibility: hidden;
    opacity: 0;
  }
  100% {
    opacity: 0.25;
  }
}
