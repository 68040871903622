.webTaskTracker {
    background: white;
    border-radius: 2rem;
    padding: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Inter;

    &__task {
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      margin-bottom: 2rem;
    }
    &__description {
      // width: max-content;
      font-size: 1.5rem;
    }
    &__type {
      font-size: 2rem;
      // font-weight: normal;
      text-transform: uppercase;
      // width: min-content;
    }
    &__track {
      margin-bottom: 1rem;
    }
    &__info {
      display: grid;
      grid-auto-flow: column;
      width: fit-content;
      // grid-auto-columns: min-content;
      align-items: center;
      column-gap: 1rem;
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    &__points {
      display: flex;
      align-items: center;
      font-size: 2.5rem;
      font-weight: bold;
    }
    &__progress {
      font-size: 2rem;
      letter-spacing: 0.1rem;
      text-align: right;
    }
  }
  