.announcement-list {
    color: #272727;
    height: 100%;

    &--background {
        background-color: var(--color-background-default);
        padding-bottom: 424px;
    }

    &__title  {
        width: 100%;
        background: #4dc591;
        padding: 50px;
        font-size: 30px;
        font-weight: bold;
        color: white;
        text-align: center;
    }

    &__list-message {
        font-size: 18px;
        text-align: center;
    }

    @media only screen and (min-width: 960px) {
        margin-left: 290px;
    }

    &__header {
        width: 100%;
        padding: 50px;
        font-weight: bold;

        hr {
            border: 1px solid #dadada;
            margin-bottom: 20px;
        }
    }

    &__tabs {
        display: flex;

        * {
            font-size: 20px;
            margin-right: 40px;
            padding: 10px 0;
        }
    }

    &__tab,
    &__tab-selected {
        cursor: pointer;
    }

    &__tab {
        color: #757575;
    }

    &__tab-selected {
        font-weight: bold;
        border-bottom: 5px solid darken(#4dc591, 40%);
    }

    &__container {
        width: 100%;
        @media only screen and (min-width: 1441px) {
            width: 1000px;
            margin: auto;
        }

        @media only screen and (min-width: 960px) and (max-width: 1440px) {
            width: 80%;
            margin: auto;
        }
    }

    &__display {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: auto;
        justify-content: center;
        align-items: center;

        & > * {
            margin-top: 20px;
            width: 350px;
        }

        @media only screen and (min-width: 960px) and (max-width: 1440px) {
            width: 75%;
            flex-direction: row;
            flex-flow: wrap;

            & > * {
                width: 400px;
                margin-left: 20px;
            }
        }

        @media only screen and (min-width: 1441px) {
            width: 1000px;
            flex-direction: row;
            flex-flow: wrap;

            & > * {
                width: 400px;
                margin-left: 20px;
            }
        }
    }
}
