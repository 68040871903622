@import "../../../style/abstracts/mixins";

.videoScreen {
  // display: grid;
  @include respond(tab-port) {
    padding-top: 6rem !important;
  }
  &__heading {
    margin-bottom: 2rem;
  }
}

.videoSuggestions {
  &__videos {
    @include respond(tab-port) {
      grid-template-columns: repeat(2, 1fr);
    }
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
  }
}

.videoScreenVideo {
  margin-bottom: 2rem;
  &__videoPlayer {
    margin-bottom: 2rem;

    &--theatre {
      background-color: black;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

  }
  &__title {
    margin-bottom: 0.5rem;
  }
  &__author {
    font-size: 5rem;
  }
  &__date {
    font-size: 1.5rem;
    font-weight: bold;
  }
  &__details {
    flex: 1;
  }
  &__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  &__buttons {
    // width: rem;
    display: flex;
    justify-content: space-between;
    height: fit-content;
  }
  &__button {
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    text-align: center;
    &:not(:last-child) {
      margin-right: 1rem;
    }
    &__text {
      font-size: 1rem;
    }
    &__icon {
      height: 5rem;
      path {
        width: 100%;
      }
    }
  }
}

.videoScreenMainDescription {
  &__paragraph {
    font-size: 1.5rem;
    line-height: 2rem;
    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
  }
  margin-bottom: 2rem;
}

.videoScreenComments {
  &__new {
    margin-bottom: 2rem;
  }
}

.tabSelector {
  @include respond(tab-port) {
    padding: 2rem 3rem;
  }
  margin-bottom: 2rem;
  padding: 2rem 5rem;
  background-color: var(--color-primary-light);
  font-size: 2rem;
  display: flex;
  justify-content: space-between;
  &__tab {
    text-align: left;
    cursor: pointer;
    padding: 1rem 0;
    flex-basis: 48%;
    border: none;
    background-color: transparent;
    outline: none;
    &-active {
      font-weight: bold;
      border-bottom: 3px solid var(--color-primary-dark);
    }
  }
}
