.articleThumbnail {
  // width: 50%;
  display: flex;
  margin-bottom: 2rem;
  &__image {
    width: 44%;
    padding-bottom: 27%;
    background-color: grey;
    margin-right: 1.5rem;
    background-size: cover;
    background-position: center;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
  &__title {
    font-weight: normal;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
}
