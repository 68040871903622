@import "../../style/abstracts/mixins";
@import "../../style/style.scss";

.landingScreen {
  &__heading {
    margin-bottom: 2rem;
    font-weight: normal;
    font-size: 5rem;
    padding: 4rem 0;
  }
  &__mainImage {
    padding-bottom: 50%;
    background-size: cover;
    background-position: center top;
    @include respond(tab-land) {
      padding-bottom: 70%;
    }
    @include respond(phone-port) {
      padding-bottom: 90%;
      background-position: center;
    }
  }
  &__mainText {
    line-height: 200% !important;
  }
}

.landingScreen {
  &Intro {
    &__buttons {
      margin: auto;
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
      width: 100%;
      font-size: 3rem;
      max-width: 50rem;
    }
  }
  &How {
    background-color: var(--color-secondary-light);
    text-align: center;
    padding: 5rem;
    padding-left: 15rem;
    padding-right: 15rem;
    @include respond(tab-port) {
      padding-right: 3rem;
      padding-left: 3rem;
    }
    &__grid {
      padding: 2rem 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include respond(phone-land) {
        padding: 2rem 2rem;
      }
    }
    &__group {
      flex-basis: 45%;
      display: grid;
      row-gap: 2rem;
      margin-bottom: 2rem;
      @include respond(phone-land) {
        flex-basis: 100%;
      }
      &:last-child {
        margin: auto;
      }
      &__icon {
        margin: auto;
      }
      &__text {
        line-height: 200% !important;
      }
    }
  }
  &Becoming {
    &__buttons {
      margin: auto;
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      font-size: 3rem;
      max-width: 50rem;
    }
    &__title {
      font-size: 2rem !important;
    }
  }
  &Features {
    background-color: var(--color-secondary-light);
    text-align: center;
    padding: 5rem;
    padding-left: 15rem;
    padding-right: 15rem;
    @include respond(tab-port) {
      padding-right: 3rem;
      padding-left: 3rem;
    }
    &__grid {
      align-items: flex-start;
      padding: 2rem 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include respond(phone-land) {
        padding: 2rem 2rem;
      }
    }
    &__group {
      flex-basis: 45%;
      display: grid;
      row-gap: 2rem;
      margin-bottom: 4rem;
      @include respond(phone-land) {
        flex-basis: 100%;
      }
      &__icon {
        width: 100%;
        margin: auto;
      }
      &__text {
        line-height: 200% !important;
      }
    }
  }
  &Testimonials {
    &__grid {
      display: grid;
    }
    &__group {
      background-color: var(--color-secondary-light);
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 5rem;
      &__content {
        position: relative;
        background-color: white;
        height: 100%;
        width: 100%;
        margin-bottom: 2rem;
        display: grid;
        align-items: center;
        padding: 7%;
        justify-items: center;
        &__quote {
          width: 8rem;
          height: 8rem;
          background-size: contain;
          background-position: center;
          margin-bottom: 2rem;
          background-repeat: no-repeat;
        }
        &__text {
          line-height: 150% !important;
          font-size: 2.5rem;
          text-align: center;
        }
      }
    }
    &__blob {
      position: absolute;
      &-1 {
        top: -5rem;
        left: -10rem;
      }
      &-2 {
        bottom: -5rem;
        right: -5rem;
      }
    }
    &__author {
      display: flex;
      align-items: center;
      &__thumbnail {
        width: 5rem;
        height: 5rem;
        border-radius: 100%;
        background-color: grey;
        margin-right: 2rem;
        background-size: cover;
        background-position: center;
      }
      &__text {
      }
      &__name {
        font-weight: bold;
        font-size: 2rem;
      }
      &__job {
        font-size: 2rem;
      }
    }
  }
  &Outro {
    &__text {
      line-height: 200%;
    }
    &__buttons {
      margin: auto;
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      font-size: 3rem;
      max-width: 50rem;
    }
  }
}
