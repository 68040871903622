.emailVerificationRequest {
  &__heading {
    margin-bottom: 1rem;
  }
  &__text {
    margin-bottom: 1rem;
  }
  &__buttons {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    width: 100%;
    font-size: 3rem;
    margin: auto;
    // min-width: 30rem;
  }
}
