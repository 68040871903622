// @import '../../style/style.scss';

.signInForm {
  // text-align: center;
  padding: 5rem;
  padding-bottom: 2rem;
  // background-color: var(--color-grey-light-2);
  // box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  &__submit {
    text-align: center;
  }
}

.errorText {
  color: var(--color-error);
  margin-bottom: 2rem;
}
