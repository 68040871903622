@import "../../../style/abstracts/mixins";

.settingsScreen {
  &__heading {
    margin-bottom: 2rem;
    border-bottom: 2px solid #dadada;
    padding-bottom: 2rem;
  }
  &__content {
    display: flex;
  }
  &__section {
    flex: 2;
  }
}

.settingsScreenNav {
  min-width: 27rem;
  display: grid;
  flex: 1;
  margin-right: 2rem;
  // grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  @include respond(phone-port) {
    // grid-template-columns: repeat(2, 1fr);
  }
  &__link {
    transition: all 0.3s;
    padding: 2rem 2rem;
    font-size: 2rem;
    display: flex;
    align-items: center;
    &:hover {
      background-color: var(--color-grey-light-3);
    }
    &-active {
      transform: translateX(1rem);
    }
    &__text {
      margin-right: auto;
    }
    &__icon {
      height: 4rem;
      width: 4rem;
      margin-right: 2rem;
    }
  }
}

.settingsScreenInterests {
  &__grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    row-gap: 2rem;
    column-gap: 2rem;
    width: 100%;
    margin-bottom: 2rem;
    @include respond(tab-port) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__counter {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}
