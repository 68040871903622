@import "../../../style/abstracts/mixins";

.videoThumbnail {
  transition: all .3s;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12),
    0px 1px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  & > a {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &:hover {
    transform: translateY(-3px);
  }

  &:hover &__thumbnail {
    filter: brightness(1.2);
  }

  &__thumbnail {
    transition: all .3s;
    position: relative;
    padding-bottom: 60%;
    width: 100%;
    background-size: 110%;
    background-position: center;
    // margin-bottom: 1rem;
    background-color: rgb(216, 216, 216);
  }
  &__content {
    font-size: 3rem;
    // background-color: var(--color-black);
    padding: 1.5rem 1.5rem;
    flex: 1;
    // color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  &__title {
    font-family: var(--font-secondary);
    font-weight: normal;
    margin-bottom: 1rem;
    font-size: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__play {
    @include absCenter;
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  &__author {
    display: flex;
    // text-align: left;
    align-items: center;
    &Name {
      font-weight: bold;
      font-family: var(--font-secondary);
      font-size: 2rem;
    }
    &Job {
      font-size: 1.5rem;
    }
    &Text {
      flex: 1;

      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
    &Image {
      box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.15);
      transition: all 0.5s;
      background-color: var(--color-black);
      border-radius: 100%;
      background-size: 120%;
      background-position: center;
      background-repeat: no-repeat;
      padding-bottom: 0;
      margin-bottom: 0;
      width: 7rem;
      height: 7rem;
      margin-right: 1rem;
    }
  }
}
