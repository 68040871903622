.formRadio {
  display: inline-block;
  padding: 0.5rem 0;

  &__input {
    display: none;
  }

  &__label {
    font-size: 2rem;
    cursor: pointer;
    position: relative;
    padding-left: 4.5rem;
  }

  &__button {
    height: 3rem;
    width: 3rem;
    border: 5px solid var(--color-primary);
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: -0.4rem;

    &::after {
      content: "";
      display: block;
      height: 1.3rem;
      width: 1.3rem;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--color-primary);
      opacity: 0;
      transition: opacity 0.2s;
    }
  }

  &__input:checked ~ &__label &__button::after {
    opacity: 1;
  }
}
