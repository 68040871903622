.popUp {
  // color: white;
  z-index: 70;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(black, 0.25);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__bg {
    top: 0;
    left: 0;
    background-color: rgba(black, 0.25);
    width: 100%;
    height: 100%;
    position: fixed;
  }
  &__content {
    max-height: 90vh;
    position: relative;
    width: 80vw;
    min-width: 30rem;
    max-width: 80rem;
    padding: 3rem;
    background-color: var(--color-white);
  }
}
