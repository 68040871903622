@import "../../../style/abstracts/mixins";
.mentorsListScreen {
  &__heading {
    margin-bottom: 2rem;
  }
}

.mentorsListScreenMentors {
  display: grid;
  row-gap: 3rem;
  column-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: min-content;
  @include respond(tab-land) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond(phone-port) {
    grid-template-columns: repeat(1, 1fr);
  }
}
