.buttonAdd {
  display: flex;
  justify-content: center;
  align-items: center;
  &__circle {
    width: 30%;
    padding-bottom: 30%;
    position: relative;
    border-radius: 100%;
    border: 2px solid currentColor;
  }
  &__plus {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 5rem;
  }
}
