// @import '../../style/style.scss';

.mentorThumbnail {
  text-align: center;
  width: 100%;
  &:hover {
    .mentorThumbnail {
      &__thumbnail {
        background-size: 100%;
      }
    }
  }
  &-wide {
    display: flex;
    text-align: left;
    align-items: center;
  }
  &__thumbnail {
    box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.15);
    transition: all 0.5s;
    width: 100%;
    background-color: var(--color-black);
    padding-bottom: 100%;
    border-radius: 100%;
    background-size: 120%;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 1rem;
    &-wide {
      padding-bottom: 0;
      margin-bottom: 0;
      width: 7rem;
      height: 7rem;
      margin-right: 1rem;
    }
  }
  &__text {
    flex: 1;
    &-wide {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  }
  &__name {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: var(--font-secondary);
    &-wide {
      font-size: 2rem;
    }
  }
  &__job {
    word-break: break-word;
    font-size: 1.5rem;
    &-wide {
      word-break: break-word;
      font-size: 1.5rem;
    }
  }
}
