// @import '../../style/style.scss';

.mentorDescription {
  cursor: pointer;
  display: flex;
  text-align: left;
  align-items: flex-start;
  width: 100%;
  &:hover {
    .mentorDescription {
      &__thumbnail {
        background-size: 100%;
      }
    }
  }

  &__thumbnail {
    transition: all 0.5s;
    border-radius: 100%;
    background-size: 120%;
    background-position: center;
    background-repeat: no-repeat;
    width: 10rem;
    min-width: 3rem;
    min-height: 3rem;
    padding-bottom: 10rem;
    margin-right: 1.5rem;
  }
  &__text {
    flex: 1;
    height: 100%;
    // display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 1.5rem;
  }
  &__name {
    word-break: word-break;
    font-size: 3rem;
    font-family: var(--font-secondary);
    margin-bottom: 0.5rem;
    // font-size: 1.5rem;
  }
  &__job {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    // font-size: 1rem;
  }
}
