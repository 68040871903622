.paragraphCreator {
  &__header {
    text-align: center;
  }
  &__buttons {
    text-transform: capitalize;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    width: fit-content;
    margin: auto;
  }
}
