.blog-post {
    color: #272727;
    height: 100%;
    padding-bottom: 10rem;
    font-family: Inter;

    &--background {
        background-color: var(--color-background-default);
    }

    &__popup {
        &-warning {
            font-size: 20px;
            color: rgba(0, 0, 0, 0.87);
        }

        &-options {
            position: absolute;
            bottom: 15px;
            right: 15px;
            display: flex;
            flex-direction: row-reverse;

            & > * {
                font-size: 14px;
                font-weight: 500;
                color:darken(#4DC591, 40%);
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }

    @media only screen and (min-width: 960px) {
        margin-left: 290px;
    }

    &__section {
        width: 95%;
        margin: auto;
    }

    &__image,
    &__image-container {
        overflow: hidden;
        margin-bottom: 2rem;
        height: 200px;
        position: relative;
        width: 100vw;

        @media only screen and (min-width: 1441px) {
            height: 500px;
            position: static;
            width: 100%;
        }

        @media only screen and (min-width: 960px) and (max-width: 1440px) {
            height: 400px;
            position: static;
            width: 100%;
        }

        @media only screen and (min-width: 650px) and (max-width: 960px) {
            height: 250px;
            position: static;
            width: 100%;
        }
    }

    &__image {
        object-fit: cover;
    }

    &__title {
        width: 95%;
        margin: 2rem auto;

        h1,
        h2 {
            color: #00664f;
        }

        h1 {
            font-size: 40px;
        }

        h2 {
            font-size: 28px;
        }
    }

    &__author {
        &-written-by {
            font-size: 20px;
        }

        &-card {
            width: 350px;
            background: white;
            border-radius: 20px;
            display: flex;
            height: 100px;
            margin: 2rem 0;
            justify-content: center;
            align-items: center;

            & > * {
                margin-left: 1rem;
            }
        }

        &-image {
            border-radius: 50%;
            object-fit: cover;
            width: 60px;
            height: 60px;
        }

        &-name {
            font-size: 2rem;
        }

        &-career {
            font-size: 14px;
        }
    }

    &__sub-title,
    &__text {
        font-size: 2rem;
        word-break: break-word;
        margin-top: 1rem;
        margin-bottom: 5rem;
    }

    &__sub-title {
        font-weight: bold;
    }

    &__career {
        display: flex;

        &-item {
            border-radius: 20px;
            background-color: white;
            padding: 1rem;
            font-size: 2rem;
        }
    }

    &__comment {
        &-input {
            border-radius: 10px;
            outline: none;
            padding: 2rem;
            width: 95%;
            border: 2px solid #4dc591;
            display: block;
            font-size: 2rem;
        }

        &-buttons {
            display: flex;
            flex-direction: row-reverse;
            width: 90%;
            margin: 2rem auto;
            align-items: center;
        }

        &-cancel,
        &-submit {
            outline: none;
            font-size: 2rem;
            cursor: pointer;
        }

        &-submit {
            padding: 1rem 5rem;
            border-radius: 1rem;
            background: #4dc591;
            font-weight: 800;
            outline: none;
            border: none;
        }

        &-cancel {
            color: #757575;
            margin: 0 2rem;
            font-weight: 600;
        }
    }

    &__header {
        width: 100%;
        background-color: #4dc591;
        color: white;
        padding: 3.125rem;
        text-align: center;
        font-size: 18px;
        font-weight: bold;

        @media only screen and (min-width: 960px) {
            font-size: 30px;
            margin-bottom: 5rem;
        }
    }

    &__container {
        width: 100%;
        @media only screen and (min-width: 1441px) {
            width: 1000px;
            margin: auto;
        }

        @media only screen and (min-width: 960px) and (max-width: 1440px) {
            width: 80%;
            margin: auto;
        }
    }

    &__likes-section {
        display: flex;
        width: 50px;
        flex-direction: column;
        text-align: center;
    }

    &__error {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px;
        height: calc(100vh - 300px);
    }
}

button:disabled {
    cursor: not-allowed;
}

.blog-comments {
    &__buttons {
        margin-left: auto;
        
        .MuiIconButton-root {
            background: #F1F1EE;
            margin-right: 10px;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
    }

    &__comment {
        padding: 20px;
        margin-bottom: 25px;
        background: white;
        border-radius: 10px;
        width: 95%;
        font-size: 18px;

        &-metadata--format {
            display: flex;
            margin-bottom: 10px;
            align-items: center;
            height: 30px;

            & > * {
                margin-right: 20px;
            }
        }

        &-user {
            font-weight: bold;
        }

        &-date,
        &-mentor_tag {
            font-size: 14px;
        }

        &-mentor_tag {
            color: #00664f;
            font-weight: 600;
            padding: 10px;
            background: linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 0.8),
                    rgba(255, 255, 255, 0.8)
                ),
                #4dc591;
            border-radius: 50px;
        }

        &-text, &-edit {
            margin-bottom: 10px;
        }

        &-text {
            overflow-wrap: break-word;;
            margin-bottom: 10px;
        }

        &-edit {
            font-family: inherit;
            border: 2px solid #4DC591;
            border-radius: 10px;
            padding: 12px;
            width: 80%;
            resize: none;
            outline: none;
            display: block;
            margin-bottom: 0;
        }

        &-submit-edit {
            font-size: 16px;
            height: 40px;
            width: 150px;
            border-radius: 10px;
            background: #4dc591;
            font-weight: 800;
            outline: none;
            border: none;
            margin: none;
            margin-left: 15px;
            text-align: center;
        }
    }

    &__reply {
        overflow-wrap: break-word;;
        margin-bottom: 20px;

        &-input {
            border-radius: 10px;
            outline: none;
            padding: 1rem;
            width: 80%;
            border: 2px solid #4dc591;
            display: block;
            font-size: 14px;
            margin-right: 10px;
        }

        &-buttons--format {
            display: flex;
        }

        &-submit {
            font-size: 16px;
            font-weight: bold;
            border-radius: 8px;
            width: 100px;
            outline: none;
            border: none;
            background: #4DC591;
        }

        &-display-toggle {
            display: flex;
            align-items: center;
            text-decoration: underline;
            font-weight: bold;
            color: #00664F;
            margin-bottom: 10px;
        }
    }

    &__replies {
        margin-left: 35px;
    }

    &__edit-flex {
        display: flex;
        align-items: flex-end;
    }
}

.MuiIconButton-label{
    color: black !important;
}