@import "../../../style/abstracts/mixins";

.buttonTransparent {
  transition: all 0.3s;
  border: 1px solid currentColor;
  padding: 1rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
  font-family: var(--font-secondary);
  outline: none;
  background-color: transparent;
  color: var(--color-primary);
  &:hover {
    color: var(--color-black);
    border: none;
  }
  &-success {
    color: var(--color-primary);
    &:hover {
      background-color: var(--color-primary);
    }
  }
  &-danger {
    color: var(--color-error);
    &:hover {
      background-color: var(--color-error);
    }
  }
  &-neutral {
    color: var(--color-white);
    &:hover {
      background-color: var(--color-white);
    }
  }
}
