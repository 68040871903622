.trackBump {
  height: 4rem;
  width: 100%;
  padding: 0.3rem;
  background-color: white;
  border-radius: 10rem;
  overflow: hidden;
  &__progress {
    border-radius: 10rem;
    transition: width 0.5s;
    height: 100%;
    width: 100%;
    // background-color: green;
    overflow: hidden;
    display: grid;
    grid-auto-flow: column;
    column-gap: 0.3rem;
    grid-auto-columns: 1fr;
  }
  &__bump {
    flex: 1;
    height: 100%;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    &-active {
      background-color: var(--color-primary-light);
    }
  }
  &__check {
    width: 30%;
    height: 2rem;
  }
}
