@import "../../../style/abstracts/mixins";

.mentorDashboard {
  &__title {
    word-break: break-all;
    font-size: 2rem;
  }
}

.mentorDashboard {
  &Careers {
    padding: 3rem !important;
    background-color: var(--color-grey-light-3);
    @include respond(tab-port) {
      border-radius: 0 !important;
    }
    &__grid {
      font-size: 3rem;
      display: grid;
      column-gap: 3rem;
      row-gap: 3rem;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 20rem;
      margin-bottom: 2rem;
      @include respond(tab-land) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include respond(tab-port) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include respond(phone-land) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  &Articles {
    padding: 3rem !important;
    @include respond(tab-port) {
      border-radius: 0 !important;
    }
    &__grid {
      font-size: 3rem;
      display: grid;
      column-gap: 3rem;
      row-gap: 3rem;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 20rem;
      margin-bottom: 2rem;
      @include respond(tab-land) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include respond(tab-port) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include respond(phone-land) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  &Students {
    padding: 3rem !important;
    @include respond(tab-port) {
      border-radius: 0 !important;
    }
    background-color: var(--color-grey-light-2);
    &__grid {
      font-size: 3rem;
      display: grid;
      column-gap: 3rem;
      row-gap: 3rem;
      grid-template-columns: repeat(4, 1fr);
      //   grid-auto-rows: 20rem;
      padding: 0 2rem;
      margin-bottom: 2rem;
      @include respond(tab-land) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include respond(tab-port) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include respond(phone-land) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
