.blog-list {
    color: #272727;
    height: 100%;
    padding-bottom: 500px;

    &--background {
        background-color: var(--color-background-default);
    }

    @media only screen and (min-width: 960px) {
        margin-left: 290px;
    }

    &__header {
        width: 100%;
        background: #4dc591;
        padding: 50px;
        font-size: 30px;
        font-weight: bold;
        color: white;
        text-align: center;
        margin-bottom: 40px;
    }

    &__page-desc {
        text-align: center;
        font-size: 18px;

        h1 {
            color: #00664f;
            font-size: 28px;
        }

        p {
            display: inline-block;
            width: 90%;
            margin: auto;

            @media only screen and (min-width: 960px) and (max-width: 1440px) {
                width: 60%;
            }

            @media only screen and (min-width: 1441px) {
                width: 864px;
            }
        }
    }

    &__display {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: auto;
        justify-content: center;
        align-items: center;
        
        & > * {
            margin-top: 20px;
            width: 350px;
        }

        @media only screen and (min-width: 960px) and (max-width: 1440px) {
            width: 75%;
            flex-direction: row;
            flex-flow: wrap;

            & > * {
                width: 400px;
                margin-left: 20px;
            }
        }

        @media only screen and (min-width: 1441px) {
            width: 1000px;
            flex-direction: row;
            flex-flow: wrap;

            & > * {
                width: 400px;
                margin-left: 20px;
            }
        }
    }
}
