.blog-form {
    margin: auto;
    font-family: 'Inter', sans-serif;
    color: black;
    font-size: 2rem;
    color: #272727;
    padding-bottom: 5rem;

    @media only screen and (min-width: 1441px) {
        width: 1000px; 
        
        &__container {
            margin-left: 27.5rem;
        }
    }

    @media only screen and (min-width: 960px) and (max-width: 1440px){
        width: 75%; 

        &__container {
            margin-left: 27.5rem;
        }
    }

    @media only screen and (min-width: 650px) and (max-width: 960px) {
        width: 65%; 
    }


    &__section, &__submit-container {
        width: 90%;
        margin: 2rem auto;
    }

    &__field {
        display: block;
        outline: none;
        border-radius: 10px;
        width: 100%;
        height: 5rem; 
        font-size: 2rem;
        padding: 2rem;
        border: 2px solid #4DC591;
    }

    &__career-field {
        border: 2px solid #4DC591;
        border-radius: 10px;
        padding: 0.5rem;
        width: 100%;
        background: white;
    }

    &__image-field {
        background-color: darken(#4DC591, 40%);
        color: white;
        margin: auto;
        text-align: center;
        display: block;

        padding: 1rem;
        border-radius: 50px;
        width: 75px;

        @media only screen and (min-width: 960px){
            padding: 12px 60px;
            width: 180px;
            height: 50px;
            border-radius: 1rem;
            font-size: 18px;
        }

        @media only screen and (max-width: 959px) {
            padding: 1rem;
            border-radius: 50px;
            width: 75px;
            font-size: 14px;
        }

    }

    #related-careers {
        width: 100%;
        padding: 1rem;
        border-radius: 10px;
        border: 2px solid #4DC591;
        outline: none !important;
    }
    

    &__label {
        display: block;
        margin-bottom: 1rem;

        label {
            font-weight: bold;
        }
    }

    &__placeholder-image {
        display: flex;
        background: #DADADA;
        justify-content: center;
        margin-bottom: 2rem;
        align-items: center;
        height: 200px;
        position: relative;
        width: 100vw;
        left: -20px;

        @media only screen and (min-width: 1441px) {
            height: 450px;
            position: static;
            width: 100%;
        }

        @media only screen and (min-width: 960px) and (max-width: 1440px){
            height: 400px;
            position: static;
            width: 100%;
        }

        @media only screen and (min-width: 650px) and (max-width: 960px) {
            height: 250px;
            position: static;
            width: 100%;
        }
    }

    &__image-container, &__image {
        overflow: hidden;
        margin-bottom: 2rem;
        height: 200px;
        position: relative;
        width: 100vw;
        left: -20px;

        @media only screen and (min-width: 1441px) {
            height: 450px;
            position: static;
            width: 100%;
        }

        @media only screen and (min-width: 960px) and (max-width: 1440px){
            height: 400px;
            position: static;
            width: 100%;
        }

        @media only screen and (min-width: 650px) and (max-width: 960px) {
            height: 250px;
            position: static;
            width: 100%;
        }

    }

    &__image {
        background-size: contain;
        object-fit: cover;
    }

    &__placeholder-image-icon {
        margin: auto;
        background: white;
        border-radius: 50%;
        padding: 2.5rem;

    }

    &__button-save, &__button-submit {
        display: inline-block;
        outline: none;
        border: none;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
    }

    &__button-submit {
        width: 150px;
        height: 50px;
        border-radius: 16px;
        background: #4DC591;
    }

    &__button-save {
        background: none;
        padding: 2rem;
    }
}


