.formRadioGroup {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  &__radios {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: repeat(3, max-content);
  }
}
