.starSvg {
}

.star {
  display: inline-block;
  &-small {
    height: 2.3rem;
    width: 2.3rem;
  }
  &-medium {
    // margin-left: 1rem;
    height: 3rem;
    width: 3rem;
  }
  &-large {
    height: 8rem;
    width: 8rem;
  }
  &-bronze {
    path {
      fill: #cd7f32;
    }
  }
  &-bronze {
    path {
      fill: #c0c0c0;
    }
  }
  &-bronze {
    path {
      fill: #ffd700;
    }
  }
}
