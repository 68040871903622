.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}
.u-margin-bottom-medium {
  margin-bottom: 4rem !important;

  @include respond(tab-port) {
    margin-bottom: 3rem !important;
  }
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;

  @include respond(tab-port) {
    margin-bottom: 5rem !important;
  }
}

.u-margin-bottom-huge {
  margin-bottom: 12rem !important;
}

.u-margin-top-small {
  margin-top: 1.5rem !important;
}
.u-margin-top-medium {
  margin-top: 4rem !important;

  @include respond(tab-port) {
    margin-top: 3rem !important;
  }
}
.u-margin-top-big {
  margin-top: 8rem !important;
}
.u-margin-top-huge {
  margin-top: 12rem !important;
}

//shapes

.color-primary {
  color: var(--color-primary);
}

.color-secondary {
  color: var(--color-secondary);
}

.color-tertiary {
  color: var(--color-tertiary);
}

.color-white {
  color: var(--color-white) !important;
}

.color-black {
  color: var(--color-black);
}

//typography
.emphasis {
  font-weight: bold;
  text-transform: capitalize;
}

.screen {
  display: grid;
  grid-auto-rows: min-content;
  min-height: 90rem;
  padding: var(--padding-screen-large-top) var(--padding-screen-large-side) 0
    var(--padding-screen-large-side);

  @include respond(tab-port) {
    padding-left: var(--padding-screen-medium-side);
    padding-right: var(--padding-screen-medium-side);
  }

  @include respond(phone-land) {
    padding: var(--padding-screen-large-top) var(--padding-screen-small-side) 0
      var(--padding-screen-small-side);
  }
}

.fullWidth {
  margin-left: calc(var(--padding-screen-large-side) * (-1));
  margin-right: calc(var(--padding-screen-large-side) * (-1));

  @include respond(tab-port) {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  @include respond(phone-land) {
    margin-left: calc(var(--padding-screen-small-side) * (-1));
    margin-right: calc(var(--padding-screen-small-side) * (-1));
  }
  &-conditional {
    margin-left: 0;
    margin-right: 0;
    @include respond(tab-port) {
      margin-left: calc(var(--padding-screen-small-side) * (-1));
      margin-right: calc(var(--padding-screen-small-side) * (-1));
    }
  }
  &-padded {
    padding-left: var(--padding-screen-current-side);
    padding-right: var(--padding-screen-current-side);

    @include respond(tab-port) {
      padding-left: var(--padding-screen-current-side);
      padding-right: var(--padding-screen-current-side);
    }
  }
}

.hoverEffect {
  // transition: box-shadow .3s, transform .3s;
  &:hover {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px) scale(1.01);
  }
}

.card {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
}

.focusEffect {
  // transition: box-shadow .3s, transform .3s;
  &:focus {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px) scale(1.01);
  }
}

.borderRadius {
  &-small {
    border-radius: 5px;
  }
  &-medium {
    border-radius: 10px;
  }
  &-large {
    border-radius: 20px;
  }
}

.u-align-center {
  display: flex;
  align-items: center;
}
