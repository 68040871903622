.formUploadImage {
  backface-visibility: hidden;
  height: 15rem;
  width: 15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    outline: none;
    color: inherit;
    border: none;

    // &:valid {
    //   box-shadow: 0 1rem 2rem rgba(black, 0.1);
    //   border-bottom: 3px solid var(--color-primary);
    // }

    // &:not(:placeholder-shown):invalid {
    //   border-bottom: 3px solid var(--color-error);
    //   //   border-bottom: 3px solid $color-secondary-dark;
    // }

    // &::-webkit-input-placeholder {
    //   color: var(--color-grey-dark-2);
    // }
  }
  &__label {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    background-position: center;
    background-size: cover;
  }
}
