:root {
  // COLORS
  $color-primary: #4dc591;
  --color-primary: #4dc591;
  $color-primary-light: #dbf3e9;
  --color-primary-light: #dbf3e9;
  $color-primary-dark: #259665;
  --color-primary-dark: #259665;

  $color-secondary: #ffe54f;
  --color-secondary: #ffe54f;
  $color-secondary-light: #fff8f0;
  --color-secondary-light: #fff8f0;
  $color-secondary-dark: #ffd700;
  --color-secondary-dark: #ffd700;

  $color-tertiary: #cd7f32;
  --color-tertiary: #cd7f32;
  $color-tertiary-light: #ffc278;
  --color-tertiary-light: #ffc278;
  $color-tertiary-dark: #cc6d0e;
  --color-tertiary-dark: #cc6d0e;

  $color-error: #ff6066;
  --color-error: #ff6066;
  $color-success: #008000;
  --color-success: green;
  $color-warning: #ffff00;
  --color-warning: yellow;

  $color-grey-light-1: #f7f7f7;
  --color-grey-light-1: #f7f7f7;
  $color-grey-light-2: #eee;
  --color-grey-light-2: #eee;
  $color-grey-light-3: #ccc;
  --color-grey-light-3: #ccc;

  $color-grey-dark-1: #777;
  --color-grey-dark-1: #777;
  $color-grey-dark-2: #999;
  --color-grey-dark-2: #999;
  $color-grey-dark-3: #333;
  --color-grey-dark-3: #333;

  

  $color-white: #fff;
  --color-white: #fff;

  $color-black: #272727;
  --color-black: #272727;

  $color-gold: "#ffd700";
  --color-gold: "#ffd700";

  $color-silver: "#c0c0c0";
  --color-silver: "#c0c0c0";

  $color-bronze: "#cd7f32";
  --color-bronze: "#cd7f32";

  $color-background-default: #f1f1ee;
  --color-background-default: #f1f1ee;

  // FONT
  $font-primary: "Montserrat";
  --font-primary: "Montserrat";
  $font-secondary: "Inter";
  --font-secondary: "Inter";

  $default-font-size: 2.5rem;
  --default-font-size: 2.5rem;

  // GRID
  $grid-width: 114rem;
  $gutter-vertical: 8rem;
  $gutter-vertical-small: 6rem;
  $gutter-horizontal: 6rem;

  //MARGINS
  $padding-screen-large-side: 15rem;
  --padding-screen-large-side: 15rem;

  $padding-screen-large-top: 5rem;
  --padding-screen-large-top: 5rem;

  
  $padding-screen-medium-side: 7rem;
  --padding-screen-medium-side: 7rem;
  
  $padding-screen-medium-top: 5rem;
  --padding-screen-medium-top: 5rem;
  
  $padding-screen-small-side: 3rem;
  --padding-screen-small-side: 3rem;

  $padding-screen-small-top: 3rem;
  --padding-screen-small-top: 3rem;

  --padding-screen-current-side: var(--padding-screen-large-side);
  --padding-screen-current-top: var(--padding-screen-large-top);

  @include respond(tab-port) {
    --padding-screen-current-side: var(--padding-screen-medium-side);
    --padding-screen-current-top: var(--padding-screen-medium-top);
  }

  @include respond(phone-land) {
    --padding-screen-current-side: var(--padding-screen-small-side);
    --padding-screen-current-top: var(--padding-screen-small-top);
  }
}
