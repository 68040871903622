.buttonCard {
  cursor: pointer;
  font-size: inherit;
  transition: all 0.3s;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  background-color: var(--color-grey-light-2);
  padding: 0 1rem;
}
