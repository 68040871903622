@import "../../../../style/abstracts/mixins";

.signUpForm {
  @include respond(tab-port) {
    padding: 0;
  }
  padding: 0 5rem;
  &__buttons {
    text-align: center;
    display: grid;
    width: 100%;
    max-width: 40rem;
    margin: auto;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    margin-bottom: 2rem;
  }
  &__buttonsDivider {
    display: flex;
    align-items: center;
  }
  &__rule {
    flex: 1;
    border: none;
    height: 0.5px;
    background-color: var(--color-black);
  }
  &__or {
    margin: 0 1rem;
    font-size: 1.5rem;
    color: var(--color-black);
  }
  &__gotoSignIn {
    text-decoration: underline;
    color: var(--color-primary);
    font-size: 2rem;
    text-align: center;
    margin-bottom: 5rem;
  }
}
