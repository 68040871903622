@import "../abstracts/mixins";

.section {
  margin-bottom: 5rem;
  &-wide {
    margin-right: -2rem;
    margin-left: -2rem;
    padding: 2rem;
    @include respond(tab-port) {
      margin-right: -3rem;
      margin-left: -3rem;
      padding: 1rem;
    }
  }
  &__title {
    font-weight: normal;
    margin-bottom: 2rem;
  }
  &__content {
    margin-bottom: 2rem;
  }
  &__seeMore {
    font-size: 1rem;
    font-weight: bold;
    width: fit-content;
    margin-left: auto;
    display: block;
    * {
      text-transform: capitalize;
      font-size: inherit !important;
    }
  }
}
