@import "../../../style/abstracts/mixins";

.taskComplete {
  text-align: center;
  @include absCenter;
  padding: 3rem;
  position: fixed;
  display: flex;
  width: 40rem;
  // height: 15rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 1000;
  background-color: var(--color-black);
  font-size: 2rem;
  &__text {
    margin-bottom: 2rem;
  }
}
