@import "../../../../style/abstracts/mixins";

.settingsHistory {
  &__grid {
    @include respond(tab-land) {
      grid-template-columns: repeat(2, 1fr);
    }
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
    margin-bottom: 2rem;
  }
}
