.statusDisplay {
  cursor: pointer;
  opacity: 0;
  overflow: hidden;
  transition: all 1s;
  font-size: 1.5rem;
  background-color: green;
  position: fixed;
  left: 0;
  top: 4rem;
  padding: 0.5rem;
  // height: 3rem;
  z-index: 99;
  width: 100%;
  box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.4);
  &__open {
    opacity: 1;
    top: 10rem;
  }
  &__text {
    text-align: center;
  }
}
