@import "../../../style/abstracts/mixins";
// @import '../../style/style.scss';

.mentorLandingScreen {
  min-height: 87vh;
  padding: 10rem 5rem 5rem 5rem;
  // background-blend-mode: lighten;
  // background-color: white;

  &__bg {
    // background-image: url('/hero.jpeg');
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0.7;
  }

  &__heading {
    margin-bottom: 2rem;
    color: var(--color-primary);
    // font-size: 3rem;
  }
  &__sub {
    color: white;
    font-size: 3rem;
    margin-bottom: 10rem;
    // @include respond(phone-port){
    //     margin-bottom: 25rem;
    //     font-size: initial;
    // }
  }
  &__buttons {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    width: 40%;
    font-size: 3rem;
    margin: auto;
    min-width: 30rem;
  }
}
