// @import '../../style/style.scss';

.itemSummary {
  width: 100%;
  &__title {
    font-size: 2rem;
  }
  &__extra {
    font-size: 1.5rem;
  }
  &__description {
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }
  &__button {
    font-size: 1.7rem;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
}
