@import "../../../style/abstracts/mixins";

.mentorSignUpScreen {
  &__sub {
    text-align: center;
    margin-bottom: 2rem;
  }
  &__heading {
    text-align: center;
    margin-bottom: 1rem;
  }
  &__form {
  }
}
