@import "../../../style/abstracts/mixins";

.comment {
  width: 100%;
  &:nth-child(odd) &__head {
    background-color: var(--color-grey-light-3);
  }
  &__head {
    background-color: var(--color-grey-light-2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 1.5rem 5rem;
    @include respond(tab-port) {
      padding: 1.5rem var(--padding-screen-current-side);
    }
    &-thread {
      background-color: transparent !important;
      cursor: default;
    }
  }
  &__details {
    display: grid;
    grid-auto-flow: column;
    column-gap: 0.5rem;
    margin-bottom: 0.5rem;
    width: fit-content;
    align-items: baseline;
  }
  &__text {
    margin-right: auto;
  }
  &__authorName {
    font-size: 1.5rem;
    font-family: var(--font-secondary);
    font-weight: bold;
  }
  &__date {
    font-weight: normal;
    font-size: 1rem;
  }
  &__mentorSign {
    font-weight: normal;
    font-size: 1.5rem;
    padding: .8rem;
    background-color: var(--color-primary-light);
    border-radius: 100rem;
    color: var(--color-primary-dark);
    font-weight: bold;
  }
  &__arrow {
    transition: all 0.3s;
    transform-origin: center;
    display: flex;
    align-items: center;
  }
  &__threadInfo {
    font-size: 2.5rem;
    margin-right: 1rem;
  }
  &__delete {
    font-size: 2.5rem;
    margin-left: 2rem;
  }
  &__comment {
  }
  &__thread {
    transition: all 0.5s;
    height: fit-content;
    &-hidden {
      visibility: hidden;
      height: 0;
      opacity: 0;
      overflow: hidden;
    }
  }
  &__buttons {
    max-width: 20rem;
    display: flex;
    justify-content: space-between;
  }
  &__new {
    padding: 1.5rem 5rem;
    @include respond(tab-port) {
      padding: 1.5rem var(--padding-screen-current-side);
    }
  }
}
