// @import '../../style/style.scss';

.formBoxInput {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  position: relative;
  &__label {
    word-break: break-word;
    position: relative;
    z-index: 1;
    transition: all 0.3s;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    text-align: center;
    background-color: var(--color-grey-light-3);
    padding: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    &-selected {
      background-color: var(--color-primary);
    }
  }
  &__input {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    visibility: hidden;
    // position: absolute;
  }
}
