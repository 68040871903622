.announcement-manage {
    color: #272727;
    height: 100%;

    &--background {
        background-color: var(--color-background-default);
        padding-bottom: 450px;
    }

    &__list-message {
        font-size: 18px;
        text-align: center;
    }

    &__header {
        width: 100%;
        padding: 50px;
        font-weight: bold;

        h1 {
            font-size: 28px;
        }

        hr {
            border: 1px solid #dadada;
            margin: 20px 0;
        }
    }

    &__popup {
        &-warning {
            font-size: 20px;
            color: rgba(0, 0, 0, 0.87);
        }

        &-options {
            position: absolute;
            bottom: 15px;
            right: 15px;
            display: flex;
            flex-direction: row-reverse;

            * {
                font-size: 14px;
                font-weight: 500;
                color:darken(#4DC591, 40%);
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }

    @media only screen and (min-width: 960px) {
        margin-left: 290px;
    }

    &__tabs {
        display: flex;

        * {
            font-size: 20px;
            margin-right: 40px;
            padding: 10px 0;
        }
    }

    &__tab, &__tab-selected {
        cursor: pointer;
    }

    &__tab {
        color: #757575;
    }

    &__tab-selected {
        font-weight: bold;
        border-bottom: 5px solid darken(#4DC591, 40%);
    }

    &__container {
        width: 100%;
        @media only screen and (min-width: 1441px) {
            width: 1000px;
            margin: auto;
        }

        @media only screen and (min-width: 960px) and (max-width: 1440px) {
            width: 80%;
            margin: auto;
        }
    }

    &__display {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: auto;
        justify-content: center;
        align-items: center;
        
        & > * {
            margin-top: 20px;
            width: 350px;
        }

        @media only screen and (min-width: 960px) and (max-width: 1440px) {
            width: 75%;
            flex-direction: row;
            flex-flow: wrap;

            & > * {
                width: 400px;
                margin-left: 20px;
            }
        }

        @media only screen and (min-width: 1441px) {
            width: 1000px;
            flex-direction: row;
            flex-flow: wrap;

            & > * {
                width: 400px;
                margin-left: 20px;
            }
        }
    }
}

.ReactModal__Content.ReactModal__Content--after-open {
    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);

    @media only screen and (min-width: 961px) {
        width: 500px;   
        height: 180px; 
    }

    @media only screen and (max-width: 960px) {
        width: 280px;    
        height: 275px;
    }

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 24px 48px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: none !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}