@import "../../../style/abstracts/mixins";

.helpTag {
  margin-left: 1rem;
  display: inline-block;
  position: relative;
  &:hover &__popup {
    visibility: visible;
    opacity: 1;
  }
  &__icon {
    height: 2rem;
    width: 2rem;
  }
  &__content {
    font-family: var(--font-secondary);
    letter-spacing: 0.2rem;
    color: white;
    padding: 1rem;
    background-color: #113438;
    right: -2rem;
    bottom: 2rem;
    position: absolute;
    width: 30rem;
    font-size: 1.2rem;
    font-weight: 300;
    @include respond(phone-land) {
      width: 26rem;
    }
  }
  &__popup {
    transition: all 0.3s;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: -120%;
    &__triangle {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}
