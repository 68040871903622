.blog-featured__list {
    display: flex;
    width: 100%;
    flex-direction: column;

    & > * {
        margin-top: 25px;
    }

    .blog-card {
        &__title {
            font-size: 18px;
        }
    
        &__img {
            height: 400px;
        }
    
        &__author { 
            &-name {
                font-weight: 600;
            }
    
            &-career {
                font-size: 16px;
            }
        }
    
    }
}

