@import "../../style/abstracts/mixins";

.homeScreen {
  &__heading {
    margin-bottom: 2rem;
  }
  &__infoTop {
    position: relative;
    // box-shadow: 0px 6px 6px 0 rgba(0, 0, 0, 0.1);
    margin-top: calc(var(--padding-screen-medium-top) * (-1));
    padding-top: var(--padding-screen-medium-top);
  }
}

.homeScreenVideos {
  &__videos {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
    @include respond(tab-land) {
      grid-template-columns: repeat(1, 1fr);
    }
    // @include respond(phone-port){
    //   grid-template-columns: repeat(1, 1fr);
    // }
  }
}

.homeScreenMentors {
  position: relative;
  margin-left: -1rem;
  margin-right: -1rem;
  // margin-bottom: 1.5rem;
  padding: 2rem;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.35);
  @include respond(tab-port) {
  }
  &__mentors {
    @include respond(tab-port) {
      grid-template-columns: repeat(3, 1fr);
    }
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
    // justify-items: center;;
  }
}

.homeScreenExploreCareers {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: var(--color-primary-light);
  @include respond(tab-land) {
    font-size: 3rem;
  }
  &__careers {
    @include respond(tab-port) {
      grid-template-columns: repeat(2, 1fr);
    }
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
  }
}

.homeScreenTasks {
  height: 0;
  transition: max-height 2s;
  // font-size: 0 !important;
  overflow: hidden;
  background-color: var(--color-secondary-light);
  // margin-left: -5rem;
  // margin-right: -5rem;
  // padding-right: 5rem;
  // padding-left: 5rem;
  // @include respond(tab-port) {
  //   margin-left: -3rem;
  //   margin-right: -3rem;
  //   padding-right: 3rem;
  //   padding-left: 3rem;
  // }
  &-open {
    height: fit-content;
  }
  &__trigger {
    cursor: pointer;
    position: absolute;
    bottom: 1rem;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  &__tasks {
    padding: 2rem 0 4rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
    @include respond(tab-port) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.homeScreenGettingStarted {
  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  &__sub {
    // margin-bottom: 1rem;
  }
  &__points {
    font-size: 2rem;
    display: flex;
  }
  margin-bottom: 2rem;
}

.homeScreenUserDetails {
  //   position: relative;
  margin-bottom: 2rem;
  &__points {
    display: inline-block;
    text-align: right;
  }
  &__title {
    font-size: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.homeScreenProgress {
  // position: relative;
  margin-top: 5rem;
  height: 10rem;
  display: flex;
  align-items: center;
  &__track {
    width: 100%;
    position: relative;
    height: 0;
    border-top: 3px solid var(--color-black);
  }
  &__star {
    position: absolute;
    transform: translate(-50%, -65%);
    top: 40%;
  }
  &__points {
    font-size: 1.5rem;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 2.5rem;
  }
  &__mark {
    position: absolute;
    transform: translate(-50%, -50%);
    top: -2rem;
    height: 2.5rem;
    width: 2.5rem;
  }
}
