.blog-mentor-card {
    padding: 25px;
    color: #272727;
    font-size: 16px;
    border-radius: 20px 20px 0px 0px;
    background: white;
    text-align: center;
    box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.15);

    &__date-text {
        color: rgba(117, 117, 117, 1);
        font-size: 16px;
    }

    &__icons-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__img, &__placeholder-image  {
        box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.15);
        height: 200px;  
        width: 100%;
        border-radius: 0px 0px 20px 20px;

    }

    &__img {
        object-fit: cover;
    }

    &__placeholder-image-icon {
        margin: auto;
        background: white;
        border-radius: 50%;
        padding: 2.5rem;
    }

    &__placeholder-image {
        display: flex;
        background: #DADADA;
        justify-content: center;
        margin-bottom: 2rem;
        align-items: center;
        position: relative;
    }
}
