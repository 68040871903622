@import "../../../style/abstracts/mixins";
.articlesListScreen {
  padding: 7rem 5rem 5rem 5rem;
  width: 100%;
  &__heading {
    margin-bottom: 2rem;
  }
  &__section {
    width: 100%;
    margin-bottom: 5rem;
  }
  &__sectionTitle {
    font-weight: normal;
    margin-bottom: 2rem;
  }
  &__sectionContent {
    margin-bottom: 2rem;
  }
  &__sectionSeeMore {
    font-size: 2rem;
    color: var(--color-tertiary);
    font-weight: bold;
    // margin-bottom: 2rem;
  }
  &__mentors {
    display: grid;
    row-gap: 2rem;
    column-gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    @include respond(tab-land) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include respond(tab-port) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
